import { auth, googleProvider } from "../system/firebase/index";
import {
	getAuth,
	onAuthStateChanged,
	signInWithEmailAndPassword,
	signOut,
	signInWithPopup,
} from "firebase/auth";
import { db, myFirebase } from "../system/firebase/index";
import {
	requestLogin,
	loginError,
	requestLogout,
	receiveLogout,
	logoutError,
	verifyRequest,
	setAuthUser,
	receiveLogin,
	verifySuccess,
	verifyFail,
} from "../system/redux/reducers/auth";
import { setMessage } from "../system/redux/reducers/user";
import { getMessage } from "../helpers/Messages";
import { navigate } from "gatsby";

export const initiateGoogleLogin = () => {
	return signInWithPopup(auth, googleProvider);
};

export const handleLoginResult = async (dispatch, result) => {
	const user = result.user;
	await verifyAuth(dispatch);
	dispatch(setMessage(""));
	navigate("/listings");
	return user;
};

export const loginWithGoogle = async (dispatch) => {
	dispatch(requestLogin());
	try {
		const result = await initiateGoogleLogin();
		return await handleLoginResult(dispatch, result);
	} catch (error) {
		if (error.code === "auth/cancelled-popup-request") {
			dispatch(setMessage(getMessage("loginModalClosed")));
		} else if (error.code === "auth/invalid-credential") {
			dispatch(setMessage(getMessage("loginSSOFailed")));
		} else {
			dispatch(setMessage(getMessage("loginFailIssue")));
		}
		dispatch(loginError());
		throw error;
	}
};

export const loginWithEmailPassword = (dispatch, params) => {
	dispatch(requestLogin());

	return signInWithEmailAndPassword(auth, params.email, params.password)
		.then(async (data) => {
			await verifyAuth(dispatch);
			dispatch(setMessage(""));
			navigate("/listings");
			return true;
		})
		.catch(async (error) => {
			dispatch(setMessage(getMessage("loginFail")));
			dispatch(loginError());
			return false;
		});
};

export const logoutUser = (dispatch) => {
	dispatch(requestLogout());
	const auth = getAuth(myFirebase);
	signOut(auth)
		.then(() => {
			dispatch(receiveLogout());
			navigate("/");
		})
		.catch((error) => {
			dispatch(setMessage(""));
			dispatch(logoutError());
		});
};

export const verifyAuth = (dispatch) => {
	dispatch(verifyRequest());
	onAuthStateChanged(auth, (user) => {
		if (user !== null) {
			const userSerialized = JSON.stringify(user);
			dispatch(setAuthUser(userSerialized));
			dispatch(receiveLogin(userSerialized));
			dispatch(verifySuccess());
		} else {
			dispatch(verifyFail());
		}
	});
};
