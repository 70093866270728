import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { logoutUser } from '../models/Users'
import { navigate } from 'gatsby'

const Logout = ({ dispatch, auth }) => {
    useEffect(() => {
        logoutUser(dispatch)
    }, [])

    useEffect(() => {
        if ( !auth.isAuthenticated ) {
            navigate(`/`)
        }
    }, [auth])

    return null
}
function mapStateToProps(state) {
    return {
        auth: state.Auth,
    }
}
export default connect(mapStateToProps)(Logout)
